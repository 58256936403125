var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-button",
        {
          staticStyle: { "margin-bottom": "10px" },
          on: { click: _vm.chooseExecuteRes },
        },
        [_vm._v("新增")]
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", height: "400" } },
        [
          _vm._l(_vm.headerData, function (item, i) {
            return _c("el-table-column", {
              key: i,
              attrs: { "min-width": "100", label: item.name, prop: item.value },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        item.value !== "photoLists"
                          ? _c("div", [_vm._v(_vm._s(scope.row[item.value]))])
                          : _vm._e(),
                        item.value == "photoLists"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "blue",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.looPic(scope.row.photoLists)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: { label: "照片数量", "min-width": "100", prop: "photoNum" },
          }),
          _c("el-table-column", {
            attrs: { label: "合格率", "min-width": "100", prop: "photoRate" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "red", cursor: "pointer" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.$index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("img-list", {
        ref: "imgRef",
        attrs: { list: _vm.picList, checkPicList: _vm.checkPicList },
      }),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }