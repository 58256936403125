<template>
<div>
    <!-- 新增弹出弹窗,这个活动明细下面的所有未被核销的执行资料 -->
    <vxe-button @click="chooseExecuteRes" style="margin-bottom:10px">新增</vxe-button>
    <el-table :data="tableData"  border height='400'>
          <el-table-column v-for="(item,i) in headerData" min-width="100"  :key="i" :label="item.name" :prop="item.value">
             <template slot-scope="scope" >
                <div v-if="item.value !=='photoLists'">{{scope.row[item.value]}}</div>
               <div v-if="item.value=='photoLists'" style="color:blue;cursor: pointer;" @click="looPic(scope.row.photoLists)">查看</div>
             </template>
          </el-table-column>
          <el-table-column label="照片数量" min-width="100" prop="photoNum"></el-table-column>
          <el-table-column label="合格率" min-width="100" prop="photoRate"></el-table-column>
          <el-table-column  label="操作">
            <template slot-scope="scope">
              <div @click="handleDelete(scope.$index)" type="text" size="small" style="color:red;cursor: pointer;">删除</div>
            </template>
          </el-table-column>
    </el-table>
    <img-list ref="imgRef" :list="picList" :checkPicList="checkPicList"></img-list>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
</div>
</template>

<script>
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';
import request from '../../../../../../../utils/request';
import ImgList from './img_list.vue';

export default {
  props: {
    formConfig: Object,
  },
  components: {
    SelectConfig,
    ImgList,
  },
  data() {
    return {
      buttonText: {
        submit: '确定',
        close: '取消',
      },
      headerData: [],
      list: [],
      tableData: [],
      picList: [],
      checkPicList: [],
    };
  },
  watch: {

    formConfig: {
      handler(newVal, oldName) {
        console.log(newVal);
        if (newVal) {
          if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
            const tempArr = [];
            for (const item of newVal.row.auditFileRespVos) {
              tempArr.push({
                ...item,
              });
            }
            this.tableData = tempArr || [];
            this.$emit('getResourceList', this.tableData);
          } else {
            this.tableData = newVal.row.auditFileRespVos ? newVal.row.auditFileRespVos : [];
          }
        }
      },
      immediate: true,
    },

  },
  created() {},
  methods: {
    // 删除
    handleDelete(index) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.tableData.splice(index, 1);
        this.formSubmitData();
      });
    },
    // 获取数据
    onGetSelect(data) {
      this.tableData = this.getReset(this.tableData, data, 'id');
      this.formSubmitData();
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    // 格式化提交数据
    formSubmitData() {
      // for (const item of this.tableData) {
      //   item.ext1 = JSON.stringify({ ...item, ext1: '' });
      // }
      this.$emit('getResourceList', this.tableData);
    },
    // 查看照片
    looPic(list) {
      console.log(list);
      this.picList = list;
      const pics = list.map((item) => item.photos).flat();
      this.checkPhoto(pics);
      this.$refs.imgRef.open();
    },
    // 获取传入 活动明细下面的所有未被核销的执行资料
    chooseExecuteRes() {
      const params = {
        functionCode: this.formConfig.row.ext63,
        data: [],
        selectionList: [],
        idKey: 'id',
        paramData: {
          actDetailCode: this.formConfig.row.actDetailCode,
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },

    // 校验图片
    checkPhoto(pics) {
      request.post('/sfa/sensegalaxyfilestatus/queryFileStatus', pics).then((res) => {
        console.log(res);
        if (res.success) {
          this.checkPicList = res.result || [];
        }
      });
    },
    // 获取列表
    getList() {
      request.post('/mdm/mdmtableconfig/query', {
        parentCode: 'CRM20201126000000098',
        function_code: this.formConfig.row.ext63,
        functionCode: this.formConfig.row.ext63,
      }).then((res) => {
        const tempArr = [];
        for (const item of res.result.column) {
          if (item.field !== 'checkTypecheckbox') {
            tempArr.push({
              value: item.field,
              name: item.title,
            });
          }
        }
        this.headerData = tempArr;
      });
    },

  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.execution {
  border-top: 1px solid #dcdfe6;
  border-left: 1px solid #dcdfe6;

  .table-th {
    word-break: break-all;
    padding: 6px 10px;
    background-color: #f8f8f9;
    border-right: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;
    height: 36px;
    line-height: 24px;
    font-size: 12px;
    font-weight: 700;
    color: #606266;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .table-td {
    align-items: center;
    display: flex;
    border-right: 1px solid #dcdfe6;
    border-bottom: 1px solid #dcdfe6;
    min-height: 36px;
    word-break: break-all;
    padding: 6px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

  .demo-image__lazy {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .img-box {
      width: 50px;
      height: 35px;
      flex-shrink: 0;
      margin: 5px 0;
    }
  }
}
</style>
